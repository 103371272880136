<template>
  <div class="dev-advice FeatureSoon">
    <div class="dev-advice-title" slot="header">
      <span class="icon18">
        <iconic name="code" />
      </span>
      <p strong>{{ $locale["development"] }}</p>
    </div>
    <div class="dev-advice-content">
      <div class="dev-advice-content-title">{{ $locale["feature_is_comming"] }}</div>
      <div class="dev-advice-content-message">
        <p>{{ $locale["feature_is_comming_desc"] }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    alertClose: function() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.dev-advice {
  &-title {
    display: flex;
    align-items: center;
    padding: $mpadding;
    gap: $mpadding;
    .icon18 {
      @include Flex(inherit, center, center);
      width: $mpadding * 3.5;
      height: $mpadding * 3.5;
      background-color: #fff;
      border-radius: 50%;
      color: $primary_color;
      font-size: 150%;
      font-weight: bold;
    }
  }
  &.ad-confirm .modal {
    &-card {
      background-color: var(--dcolor);
      background-image: var(--sec-gradient);
      color: #fff;
      min-height: 256px;
      max-width: $pocket_width;
    }
  }
  .modal-card-header-right {
    .button .iconic {
      color: #fff;
      &:hover {
        color: #fff;
      }
    }
  }
  &-content {
    text-align: center;
    padding: $mpadding 0 0 0;
    &-title {
      font-size: 180%;
      margin: 0 0 $mpadding 0;
    }
    &-message {
      margin: 0 0 $mpadding 0;
    }
  }
}
</style>
